.flex {
  display: flex;
  width: 100%;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
}

#search-form {
  margin: 16px;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 0.3rem;
  border: 2px solid rgba(211, 211, 211, 0.4);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

#search-form:focus {
  border: 2px solid #ccc;
}

.select {
  margin: 16px;
  background-color: #fff;
  padding: 5px;
  /* box-sizing: border-box; */
  border-radius: 0.3rem;

  /* border: 2px solid rgba(211, 211, 211, 0.4); */
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.custom-select {
  /* margin: 16px; */
  padding: 5px;
  box-sizing: border-box;
  border-radius: 0.3rem;
  border: 2px solid rgba(211, 211, 211, 0.4);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

select:focus {
  border: 2px solid #ccc;
}

.tables {
  display: block;
  justify-content: center;
  margin: 30px 15px;
  padding: 0 0 2rem 2rem;
}

table {
  margin: auto;
  min-width: 70%;
  max-width: 80%;
  background-color: #f2f2f2;
  border-radius: 20px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
}

h3 {
  text-align: center;
  /* font-size: 1.5rem; */
  margin: auto 0px;
}
thead {
  background: #d3d3d3;
  text-align: center;
  text-align: center;
  font-weight: bold;
}

th tr {
  text-align: center;
  font-weight: bold;
}
.tg {
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 5px;
}
.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg .tg-baqh {
  text-align: center;
  vertical-align: top;
}
.tg .tg-0lax {
  text-align: left;
  vertical-align: top;
}
