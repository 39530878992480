.container {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.container .forms {
  min-width: 70%;
  max-width: 80%;
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 36px 64px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.flex-item {
  justify-content: space-between;
  display: inline-flex;
  width: 100%;
}

#amplify-id- {
  background-color: gray;
}

.flex-item {
  display: flex;
  flex-wrap: wrap;
}
/* 2 per line in screen < 1366px */
.flex-item > * {
  /* flex: 1 0 50%; */
}
/* 3 items per line in screen > 1366px */
@media (min-width: 1366px) {
  .flex-item > * {
    /* flex: 1 0 33.3333%; */
  }
}
